<script>
//import axios from 'axios';
import serverConfig   from '@/helpers/config';
import moment from 'moment'

import projectTabPV from "@/components/widgets/projects/projectTabPV";
import connessioneTabPV from "@/components/widgets/projects/connessioneTabPV";
import gseTabPV from "@/components/widgets/projects/gseTabPV";
import inizioLavoriTabPV from "@/components/widgets/projects/inizioLavoriTabPV";
import serviziTabPV from "@/components/widgets/projects/serviziTabPV";

import documentManagerUT from "@/components/widgets/projects/documentManagerUT";

export default {
  name: "projectManagerPV",
  emits: ["getParent"], 
    props: [
      'projectID',
      'data',
    ],
  setup() {
    moment.locale('it')  
    let tk = 'Bearer '+ localStorage.getItem('tk')
    return { tk };
  },
  data() {
	return {
            UrlServer: serverConfig.EP,
            datastore: this.$store.state,
            genData:{
                country:'',
                zipCode:'',
                city:'',
                address:'',
                address_number:'',
                additional_info_to_address:'',
                cordinate_system:'',
                longitude:'',
                latitude:'',
            },
            openEnel:false,
            openGse:false,
            openServ:false,
            openIA:false,
        }
	},
	computed:{ 
		
	},
	methods:{
        getParent(){
            this.$emit('getParent')
            if (localStorage.getItem("prjTab")) {
                setTimeout(() => {
                document.getElementById(localStorage.getItem('prjTab')).click()
            }, 500);
            }            
        },
        setTab(val){
            this.$cookies.set('ptab',val)
        },      
	},
    components:{
        projectTabPV,
        connessioneTabPV,
        gseTabPV,
        inizioLavoriTabPV,
        serviziTabPV,
        documentManagerUT,
    }
};
</script>
<template>
    <h5 class="mb-2 mt-2"></h5>
        <div class="card">
            <div class="card-body">
               
                <ul class="nav nav-tabs nav-justified mb-3" id="myTab"   role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active align-middle" data-bs-toggle="tab" id="tab-docu" href="#nav-badge-documents"
                                    role="tab" aria-selected="false">
                                    Documentazione <span class="badge bg-success"></span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link " data-bs-toggle="tab" href="#nav-badge-project" id="tab-project" role="tab"
                                    aria-selected="true" >
                                    Progettazione
                                </a>
                        </li>
                    <li class="nav-item">
                        <a class="nav-link " data-bs-toggle="tab" href="#nav-badge-services" id="tab-services" role="tab" @click="$refs.getServices.getItem()" aria-selected="true">
                            Servizi 
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link " data-bs-toggle="tab" href="#nav-badge-enel" role="tab" id="tab-enel"  aria-selected="true" @click="openEnel=true">
                            <span class="">
                                <img src="@/assets/images/enel_logo.png" alt="logo enel" height="30"  width="50" />
                            </span>        
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link " data-bs-toggle="tab" href="#nav-badge-gse" role="tab" id="tab-gse" aria-selected="true">
                         <img src="@/assets/images/gse_logo.png" alt="" height="25"  width="50"/>
                        
                        </a>
                    </li>
                        <li class="nav-item">
                        <a class="nav-link " data-bs-toggle="tab" href="#nav-badge-iniziolavori" id="tab-inizioLavori" role="tab" aria-selected="true">
                        Inizio Lavori
                        </a>
                    </li>
                </ul>
                        <!-- Nav tabs -->
                        <div class="tab-content text-muted">

                            <div class="tab-pane active" id="nav-badge-documents" role="tabpanel">
                               
                                <template v-if="data">
                                    
                                    <template v-if="data.customer!=null">
                                        <template v-if="data.customer.length>0">
                                            <template v-if="!data.customer[0].nomeFascicolo">
                                                <b-alert show variant="danger" class="mb-25">
                                                    <strong> "Nome Fascicolo" non individuato: </strong> aggiorna l'anagrafica cliente, per favore.
                                                </b-alert>
                                            </template>
                                                <documentManagerUT 
                                                    :url="`${this.UrlServer}storage/by/project`"
                                                    :projectID="projectID" 
                                                    :data="data" 
                                                    :bucket="data.customer[0].nomeFascicolo + data.year.toString() + '/' + data.systemSubCategory+'/'" 
                                                    type="directory_principale" 
                                                    refDirectory="/"
                                                    fileType="multiple" 
                                                    fileAccept="*" 
                                                    title="Documentazione impianto"
                                                    v-on:getParent="getParent()"/>
                                        
                                        </template>
                                        <template v-else>
                                            <b-alert show variant="danger" class="mb-25">
                                                <strong> "Nome Fascicolo" non individuato, inserire l'anagrafica cliente</strong>, per favore.
                                            </b-alert>
                                        </template>
                                    </template>

                                </template>
                            </div>
                            <div class="tab-pane" id="nav-badge-project" role="tabpanel">
                                <template v-if="projectID && projectID!=undefined">
                                    <projectTabPV :ID="projectID" :data="data" @getParent="getParent()"/>
                                </template>                                
                            </div>
                            <div class="tab-pane" id="nav-badge-services" role="tabpanel">
                               <template v-if="projectID && projectID!=undefined">
                                <serviziTabPV :ID="projectID" :data="data" :open="openServ" ref="getServices" @getParent="getParent()"/>
                               </template>
                            </div>
                            <div class="tab-pane" id="nav-badge-enel" role="tabpanel">
                                <template v-if="projectID && projectID!=undefined">
                                    <connessioneTabPV :ID="projectID" :data="data" :open="openEnel" @getParent="getParent()"/>
                                </template>
                            </div>
                            <div class="tab-pane" id="nav-badge-gse" role="tabpanel">
                                <template v-if="projectID && projectID!=undefined">
                                    <gseTabPV :ID="projectID" :data="data" :open="openGse" @getParent="getParent()" />
                                </template>
                            </div>
                            <div class="tab-pane" id="nav-badge-iniziolavori" role="tabpanel">
                                <template v-if="projectID && projectID!=undefined">
                                    <inizioLavoriTabPV :ID="projectID" :data="data" :open="openIA"  @getParent="getParent()"/>
                                </template>
                            </div>
                        </div>
                    </div><!-- end card-body -->
                </div>
</template>