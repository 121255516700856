<script>
import { mapActions } from "vuex";
import moment from 'moment'
import serverConfig from '@/helpers/config';

import Swal from "sweetalert2";
import axios from 'axios';


export default {
  name: "serviziTabPV",
  emits: ["getParent"],  
  props: [
    'ID',
    'data',
    'open',
  ],
  setup() {
    moment.locale('it')
    let tk = 'Bearer '+localStorage.getItem('tk')
    return { tk };
  },
  data() {
    return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        results_n:0,
        showGse:false,
        item:{
          authorizationtoStartWork: {
            responsiblePerson:'',
            responsiblePersonPhone:'',
            email:'',
            type:'', // 'Comunicazione di inizio lavori','Dia', 'Duap'
            protocol:'',
            protocolDate:'',
            protocolDateTmp:0,
            terminiSilenzioAssenso:{ type: Number, default:30}, // 30 giorni di default
            scadenzaSilenzioAssenso:'', 
            scadenzaSilenzioAssensoTmp:0,
            feedbackDateEnte:'',
            feedbackDateEnteTmp:0,
            feedbackNote:'',
            status:'', // 'favorevole', 'non favorevole', 'Non favorevole, per richiesta integrazione',
            response:{type:Boolean}, // procedi alla cantierabilità TRUE/FALSE
            createdBy: this.$store.state.auth.currentUser, 
          }
        },
        atswSupplements:{        
          total:0,
          suppList:[]
        },
        
    };
  },
  validations: {
  
  },
  mounted() {
    /*
    if (this.open == true){
        this.getItem()
    }
    */
    this.getItem()
  },
  methods: {
    ...mapActions('layout', ['changeLayoutType', ]),
    axiosInterceptor(){
      axios.interceptors.response.use(
            (response) => {
                
                return response;
            },
            (error) => {
                if (error.response && error.response.status === 401) {
                  Swal.fire({
                    title:"Accesso non autorizzato",
                    text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:3500,
                  })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }
                    return Promise.reject('Unauthorized access');
                } else if (error.response && error.response.status  === 404) {
                    Swal.fire({
                      title:"Nessun risultato trovato",
                      text:"Mi spiace, nessun risultato trovato",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('The server cannot find the requested resource');
                }  else if (error.response && error.response.status  === 412) {
                  Swal.fire({
                    title:"Credenziali mancanti",
                    text:"Inserisci delle credenziali valide, per favore.",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              } else if (error.response && error.response.status  === 423) {
                    Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }                
                    return Promise.reject('The server cannot find the requested resource');
                }
                else if (error.response && error.response.status  === 500) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Internal Server Error');
                }
                else if (error.response && error.response.status  === 502) {
                    Swal.fire({
                      title:"Errore Server",
                      text:"Mi spiace, ma riscontriamo degli errori sul server.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('Bad Gateway');
                }
                else if (error.response && error.response.status  === 503) {
                  Swal.fire({
                    title:"Servizio non disponibile",
                    text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Service Unavailable');
                } else {
                  console.log(error.response.status )
                  Swal.fire({
                    title:"Errore imprevisto",
                    text:"Ops! si è verificato un problema...riprova, per favore.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  
                }

            }
          );
    },  
    convertDate(type,val){
        switch(type){
            case "protocolDate":
                this.item.protocolDateTmp =  moment(val).unix()
                break;
            case "scadenzaSilenzioAssenso":
                this.item.scadenzaSilenzioAssensoTmp =  moment(val).unix()
                break;
            case "feedbackDateEnte":
                this.item.feedbackDateEnteTmp =  moment(val).unix()
                break;             
        }
       this.updateItem()
    },
    convertDateRow(r, type,val,data){
      //atswSupplements.suppList

      for (let item of this.atswSupplements.suppList) {
        if(item._id == r ){
          switch(type){
            case "dataSpedizione":
                item.dataSpedizioneTmp =  moment(val).unix()
                break;
            case "dataEsito":
                item.dataEsitoTmp =  moment(val).unix()
                break;
            case "dataPrevEsito":
                item.dataPrevEsitoTmp =  moment(val).unix()
                break;
                
          }
        }
      }
        
       this.updateItem(r,data)
    },
    removeElement(id){
        Swal.fire(
          {
            title: this.$t("t-delete-confirm-setting-title"),
            text: this.$t("t-delete-confirm-setting-text"),
            icon: this.$t("t-delete-confirm-setting-icon"),
            showCancelButton: true,
            cancelButtonText: this.$t("t-delete-confirm-cancel-button-text"),
            cancelButtonColor: this.$t("t-delete-confirm-setting-cancelButtonColor"),
            confirmButtonColor: this.$t("t-delete-confirm-setting-confirmButtonColor"),
            confirmButtonText: this.$t("t-delete-confirm-setting-confirmButtonText")
          }
        ).then((result) => {
          if (result.value) {
            this.axiosInterceptor()
            axios.delete(`${this.UrlServer}projects/delete-pratiche/${id}`, {headers: { authorization:this.tk}} )
            .then((response)=>{
                if(response.data) {
                    this.getItem()
                    this.updateProject()
                }
            })
          }
        });

    },
    getItem(){
      
      this.axiosInterceptor()
      axios.get(`${this.UrlServer}projects/pratiche/generali/get/progetto/${this.$route.params.id}`, {headers: { authorization:this.tk}} )
        .then((response)=>{
            this.atswSupplements.suppList = response.data
            this.atswSupplements.total = response.data.length
        })
    },
    postItem(){
      let data = {
        projectID:this.$route.params.id,
        status:'',
        type:'', // 'detrazione fiscale, 'bando_regionale', 'certificazione_energetica
        nome:'', 
        dataSpedizione:'',
        dataSpedizioneTmp:0,
        dataConsegna:'',
        dataConsegnaTmp:0,  
        tempistica:0,  
        dataPrevEsito:'',
        dataPrevEsitoTmp:0,
        dataEsito:'',
        dataEsitoTmp:0,
        tipoEsito:'', // 'ok, registra convenzione', etc.
        note:'',
        noteExtra:'',
      }
      this.axiosInterceptor()
      axios.post(`${this.UrlServer}projects/pratiche/generali/register`, data, {headers: { authorization:this.tk}} )
        .then((response)=>{
            if (response.data) {
                this.getItem()
            }
        })
    },
    updateItem(id, data){
      this.axiosInterceptor()
      axios.put(`${this.UrlServer}projects/pratiche/generali/progetto/${id}`, data, {headers: { authorization:this.tk}} )
        .then(()=>{
            this.updateProject()
        })
    },
    updateProject(){
      let data = {
        praticheGen : this.atswSupplements.suppList
      }
      this.axiosInterceptor()
      axios.put(`${this.UrlServer}projects/${this.$route.params.id}`, data, {headers: { authorization:this.tk}} )
        .then(()=>{
          localStorage.setItem('prjTab','tab-services')
          this.$emit('getParent')
        })
    },
  },
  components: {
    
  },

};
</script>

<template>
    <form action="">
      <template v-if="data.gse">
        <template v-if="data.gse.gseID">
        <div class="row  mt-50 back-white" >
          <div class="col-lg-12 col-md-12 text-center h_ss">
           
                <h3>
                 Scheda lavorazione servizi (pratiche generali)
                </h3>       
            </div>      
        </div>
        
        <div class="row  mt-50 back-white" v-if="atswSupplements.total>0 || item.authorizationtoStartWork.tipoEsito=='richiesta_integrazione'" >
          <div class="col-lg-12 col-md-12 text-center mt-2">
          
            </div>      
        </div>
      <template v-if="atswSupplements">
            <div class="row  back-white"  >
                <div class="col-lg-2 col-md-12">
                    <div class="input-group ">
                      <label class="form-label">Nome</label>
                    </div>
                </div>
                <div class="col-lg-2 col-md-12">
                    <label  class="form-label">Data Spedizione</label>
                </div> 
                <div class="col-lg-1 col-md-12">
                    <label class="form-label">Tempistica</label>
                </div> 
                <div class="col-lg-2 col-md-12">
                    <label class="form-label">Previsione esito</label>
                </div> 
                <div class="col-lg-2 col-md-12">
                    <label class="form-label">Data ricezione esito</label>
                </div>         
                <div class="col-lg-3 col-md-12">
                    <label class="form-label">Note</label>        
                </div>

            </div>        
        <template v-if="atswSupplements.total > 0">
            <div v-for="i in atswSupplements.suppList" :key="i._id">
                <div class="row  back-blue-light"  >
                    <div class="col-lg-4 col-md-12">
                    </div> 
                    <div class="col-lg-2 col-md-12">
                        <select class="form-control " :id="`type_${i._id}`" placeholder="eleziona tipologia pratica" v-model="i.type"  @change="updateItem(i._id, i)" >
                            <option value="">Seleziona tipologia pratica</option>
                            <option value="detatrazione_fiscale">Detrazione fiscale</option>
                            <option value="bando_regionale">Bando regionale</option>
                            <option value="certificazione_energetica">Certificazione energetica</option>
                        </select>                        
                    </div> 
                    <div class="col-lg-2 col-md-12">
                        <b-button variant="danger" class="btn-icon waves-effect waves-light" title="Rimuovi pratica" @click="removeElement(i._id)"><i class="ri-delete-bin-5-line"></i></b-button>
                    </div>
                    <div class="col-lg-4 col-md-12">     
                    </div>
                </div>
                <div class="row  back-grey">
                    <div class="col-lg-2 col-md-12">
                        <div class="input-group mt-5">
                        <input type="text" class="form-control width-10" :id="`nome_${i._id}`" v-model="i.nome"  @blur="updateItem(i._id, i)" >
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12">
                        <div class="input-group  mt-5">
                        <input type="date" class="form-control width-10" :id="`dataSpedizione_${i._id}`"  v-model="i.dataSpedizione" @change="convertDateRow(i._id,'dataSpedizione',i.dataSpedizione,i)">
                        </div>
                    </div>
                    <div class="col-lg-1 col-md-12">
                        <div class="input-group mt-5">
                        <input type="text" class="form-control width-10" :id="`tempistica_${i._id}`" v-model="i.tempistica"  @blur="updateItem(i._id, i)" >
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12">
                        
                        <div class="input-group  mt-5">
                        <input type="date" class="form-control width-10" :id="`dataPrevEsito_${i._id}`"  v-model="i.dataPrevEsito"  @change="convertDateRow(i._id,'dataPrevEsito',i.dataPrevEsito,i)" >
                        </div>
                    </div> 
                    <div class="col-lg-2 col-md-12">
                        
                        <div class="input-group  mt-5">
                        <input type="date" class="form-control width-10" :id="`dataEsito_${i._id}`"  v-model="i.dataEsito"  @change="convertDateRow(i._id,'dataEsito',i.dataEsito,i)" >
                        </div>
                    </div>         
                    <div class="col-lg-3 col-md-12">
                        <div class="input-group  mt-5">
                            
                            <textarea class="form-control" :id="'note_'+i._id" rows="0.1" placeholder="Inserisci eventuali note" v-model="i.note" @blur="updateItem(i._id, i)"></textarea>        
                        </div>            
                    </div>

                </div>
            </div>

        </template>
    </template>

        <div class="row  back-grey mt-50" >
            <div class="col-lg-12 col-md-12 mt-30 text-center">
                <b-button pill variant="primary" class="btn-label waves-effect waves-light" @click="postItem"><i class="ri-add-line label-icon align-middle rounded-pill fs-16 me-2"></i> Aggiungi integrazione</b-button>              
            </div>
        </div>

   
        </template>
       
      </template>
    </form>
</template>
