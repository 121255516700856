<script>
import { mapActions } from "vuex";

import moment from 'moment'
import serverConfig from '@/helpers/config';

import Swal from "sweetalert2";
import axios from 'axios';


export default {
  name: "connessioneTabPV",
  emits: ["getParent"],  
  props: [
    'ID',
    'data',
    'open',
  ],
  setup() {
    moment.locale('it')
    let tk = 'Bearer '+localStorage.getItem('tk')
    return { tk};
  },
  data() {
    return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        results_n:0,
        item:{
            projectID:'',
            enelID:'',
            status:'',
            firmaCliente:'',
            docIdeCliente:'',
            regimeContrattuale:'',
            dataRichiesta:'',
            dataRichiestaTmp:0,
            codiceIDRichiesta:'',
            sopralluogoEnel:'',
            dataArrivoPrevEnel:'',
            dataRispostaEnelPrevista:'',
            dataRispostaEnelPrevistaTmp:0,
            tipologiaRispostaEnel:'', // 'preventivo'/'richiesta_integrazione'/'deroga_Terna'
            dataValiditaPrevEnel:'',
            dataValiditaPrevEnelTmp:0,
            dataScadenzaPrevEnel:'', // 45 giorni dalla 'dataValiditaPrevEnel'
            dataScadenzaPrevEnelTmp:0,
            codiceDiRint:'', // Codice di rintracciabilità
            numeroGiorniPrevisti:30,
            oneriRichiesti:0,
            oneriInclusi:0,
            oneriDaRiscuotere:0,
            note:'',
            dataAccettazione:'',
            dataAccettazioneTmp:0,
            scadenzaRichAutorizzazioni:'',
            scadenzaRichAutorizzazioniTmp:0,
            codIdePratica:0,
            dataRispostaEnel:'',
            dataRispostaEnelTmp:0,
            dataFineLavori:'',
            dataFineLavoriTmp:0,
            dataIpotesiAllaccio:'',
            dataIpotesiAllaccioTmp:0,
            dataAllaccio:'',    
            dataAllaccioTmp:0,   
            createdBy: this.$store.state.auth.currentUser, 
        },

    };
  },
    validations: {
    },

  mounted() {
    if (this.data.enel) {
      this.item = this.data.enel
    }
  },
  methods: {
    ...mapActions('layout', ['changeLayoutType', ]),
    axiosInterceptor(){
      axios.interceptors.response.use(
            (response) => {
                
                return response;
            },
            (error) => {
                if (error.response && error.response.status === 401) {
                  Swal.fire({
                    title:"Accesso non autorizzato",
                    text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:3500,
                  })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }
                    return Promise.reject('Unauthorized access');
                } else if (error.response && error.response.status  === 404) {
                    Swal.fire({
                      title:"Nessun risultato trovato",
                      text:"Mi spiace, nessun risultato trovato",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('The server cannot find the requested resource');
                }  else if (error.response && error.response.status  === 412) {
                  Swal.fire({
                    title:"Credenziali mancanti",
                    text:"Inserisci delle credenziali valide, per favore.",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              } else if (error.response && error.response.status  === 423) {
                    Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }                
                    return Promise.reject('The server cannot find the requested resource');
                }
                else if (error.response && error.response.status  === 500) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Internal Server Error');
                }
                else if (error.response && error.response.status  === 502) {
                    Swal.fire({
                      title:"Errore Server",
                      text:"Mi spiace, ma riscontriamo degli errori sul server.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('Bad Gateway');
                }
                else if (error.response && error.response.status  === 503) {
                  Swal.fire({
                    title:"Servizio non disponibile",
                    text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Service Unavailable');
                } else {
                  console.log(error.response.status )
                  Swal.fire({
                    title:"Errore imprevisto",
                    text:"Ops! si è verificato un problema...riprova, per favore.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  
                }

            }
          );
    },    
    convertDate(type,val){
        switch(type){
            case "dataRichiesta":
                this.item.dataRichiestaTmp =  moment(val).unix()
                break;
            case "dataRispostaEnelPrevista":
                this.item.dataRispostaEnelPrevistaTmp =  moment(val).unix()
                break;
            case "dataRispostaEnel":
                this.item.dataRispostaEnelTmp =  moment(val).unix()
                break;
            case "dataValiditaPrevEnel":
                this.item.dataValiditaPrevEnelTmp =  moment(val).unix()
                break;      
            case "dataScadenzaPrevEnel":
                this.item.dataScadenzaPrevEnelTmp =  moment(val).unix()
                break;  
            case "dataAccettazione":
                this.item.dataAccettazioneTmp =  moment(val).unix()
                break;  
            case "scadenzaRichAutorizzazioni":
                this.item.scadenzaRichAutorizzazioniTmp =  moment(val).unix()
                break;  
            case "dataFineLavori":
                this.item.dataFineLavoriTmp =  moment(val).unix()
                break;                
            case "dataIpotesiAllaccio":
                this.item.dataIpotesiAllaccioTmp =  moment(val).unix()
                break; 
            case "dataAllaccio":
                this.item.dataAllaccioTmp =  moment(val).unix()
                break;                 
        }
        
       this.updateItem()
    },
    addElement(){
      
      let countL = this.roofPitch.rpList.length
      
      this.roofPitch.rpList.push({
          row:countL+1,
          number:0,
          panelQty:0,
          latitute:0,
          longitude:0,
          power:0.00,
          roofShapes: '', // Forma del tetto
          roofTilt:0.00,  // inclinazione
          orientation:0.00,    // orientamento
          azimuth:0.00,
          deviationFromMax:0.00,
          note: '',
      })
      this.roofPitch.totalRP = this.roofPitch.rpList.length
      this.updateItem()
     
    },
    removeElement(n){
      let l = this.roofPitch.rpList.filter(function(el){
        return el.row !== n
      })
      this.roofPitch.rpList = l
      this.roofPitch.totalRP = l.length
      //this.regenList()
      this.updateItem()
    },
    getItem(){
      //  if( 'enel' in this.data){
        //  if( 'enelID' in this.data.enel){
          this.axiosInterceptor()
           axios.get(`${this.UrlServer}allacci/enel/${this.data.enel.enelID}`, {headers: { authorization:this.tk}} )
             .then((response)=>{
                this.results_n = response.data.length
                this.item = response.data
              })

         // }
       // }
    },   
    postItem(){
       // this.submitted = true;
        //this.v$.$touch();
        this.item.projectID=this.ID
        this.axiosInterceptor()
        axios.post(`${this.UrlServer}allacci/registra/enel`, this.item, {headers: { authorization:this.tk}} )
          .then((response)=>{
            if (response.data){
              this.item.enelID = response.data.insertId._id
              this.results_n = 1
              localStorage.setItem('prjTab','tab-enel')
              this.updateItem()
            }
            
          })
      },
    updateItem(){
       // this.submitted = true;
        //this.v$.$touch();
        this.item.projectID=this.ID
        if (this.item._id) {
          delete this.item._id
        }
        this.axiosInterceptor()
        axios.put(`${this.UrlServer}allacci/enel/${ this.item.enelID}`, this.item, {headers: { authorization:this.tk}} )
          .then((response)=>{
            if (response.data){
              
              this.results_n = 1
              this.$emit('getParent')
            }
            
          })
      },

  },
  components: {
    
  },

};
</script>

<template>
    <form action="">
      <template v-if="data.enel">
        <template v-if="data.enel.enelID">
        <div class="row  mt-50 back-white" >
          <div class="col-lg-12 col-md-12 text-center h_impiantino">
                <h3>Richiesta rivolta all'Enel
                </h3>       
            </div>      
        </div>            
        <div class="row  back-grey" >
            <div class="col-lg-3 col-md-12 ">
              <label for="dataRichiestaInput" class="form-label">Data richiesta di connessione</label>
              <div class="input-group" >
                <input type="date" class="form-control width-20" id="dataRichiestaInput" v-model="item.dataRichiesta "  @change="convertDate('dataRichiesta',item.dataRichiesta)" >
              </div>
            </div>          
            <div class="col-lg-3 col-md-12">
              <label for="codiceIDRichiestaInput" class="form-label">Codice ID richiesta (eventuale)</label>
              <div class="input-group">
                <span class="input-code-text" id="inputGroup-sizing-default"></span>
                <input type="number" class="form-control width-20" id="codiceIDRichiestaInput" v-model="item.codiceIDRichiesta"  @change="updateItem" >
              </div>            
            </div>
            <div class="col-lg-3 col-md-12">
              <label for="sopralluogoEnelInput" class="form-label">Sopralluogo Enel</label>
                <select class="form-control" id="sopralluogoEnelInput" v-model="item.sopralluogoEnel"  @change="updateItem">
                    <option value="previsto">Previsto</option>
                    <option value="non_previsto">Non previsto</option>
                </select>
            </div>
                        
        </div>
        <div class="row  mt-50 back-white" >
          <div class="col-lg-12 col-md-12 text-center h_enel">
                <h3>Feedback Enel</h3>       
            </div>      
        </div>    
        <div class="row  back-grey" >
            <div class="col-lg-3 col-md-12 ">
              <label for="dataRispostaEnelPrevista" class="form-label">Risposta attesa per (previsione automatica)</label>
              <div class="input-group" >
                <input type="date" class="form-control width-20" id="dataRispostaEnelPrevista" v-model="item.dataRispostaEnelPrevista"  @change="convertDate('dataRispostaEnelPrevista',item.dataRispostaEnelPrevista)" >
              </div>
            </div>  
            <div class="col-lg-3 col-md-12">
              <label for="dataRispostaEnel" class="form-label">Risposta ricevuta il:</label>
              <div class="input-group">
                <input type="date" class="form-control width-20" id="dataRispostaEnel" v-model="item.dataRispostaEnel"  @change="convertDate('dataRispostaEnel',item.dataRispostaEnel)" >
              </div>            
            </div>                    
            <div class="col-lg-3 col-md-12">
              <label for="tipologiaRispostaEnel" class="form-label">Tipologia risposta Enel</label>
              <div class="input-group">
                <select class="form-control" id="tipologiaRispostaEnel" v-model="item.tipologiaRispostaEnel"  @change="updateItem">
                    <option value="preventivo">preventivo</option>
                    <option value="richiesta_integrazione">richiesta integrazione</option>
                    <option value="deroga_Terna">deroga Terna</option>
                </select>
                </div>            
            </div>
        </div>
        <template v-if="item.tipologiaRispostaEnel=='preventivo'">
            <div class="row  mt-50 back-white">
                <div class="col-lg-12 col-md-12 text-center mt-10 h_enel">
                    <h4>Informazioni relative al preventivo Enel</h4>       
                </div>      
            </div>    
            <div class="row  back-grey" >
                <div class="col-lg-3 col-md-12">
                <label for="dataValiditaPrevEnel" class="form-label">Data validità preventivo</label>
                <div class="input-group" >
                    <input type="date" class="form-control width-20" id="dataValiditaPrevEnel" v-model="item.dataValiditaPrevEnel"  @change="convertDate('dataValiditaPrevEnel',item.dataValiditaPrevEnel)" >
                </div>
                </div>  
                <div class="col-lg-3 col-md-12">
                <label for="dataScadenzaPrevEnel" class="form-label">Scadenza prevista per il:</label>
                <div class="input-group">
                    <input type="date" class="form-control width-20" id="dataScadenzaPrevEnel" v-model="item.dataScadenzaPrevEnel"  @change="convertDate('dataScadenzaPrevEnel',item.dataScadenzaPrevEnel)" >
                </div>            
                </div>                    
                <div class="col-lg-3 col-md-12">
                <label for="codiceDiRint" class="form-label">Codice di rintracciabilità</label>
                <div class="input-group">
                    <input type="input" class="form-control width-20" id="codiceDiRint" v-model="item.codiceDiRint"  @change="updateItem" >
                    </div>            
                </div>
            </div>   
            <div class="row  back-grey" >
                <div class="col-lg-3 col-md-12">
                <label for="numeroGiorniPrevisti" class="form-label">Lavori previsti in gg:</label>
                <div class="input-group" >
                    <select class="form-control" id="numeroGiorniPrevisti" v-model="item.numeroGiorniPrevisti"  @change="updateItem">
                        <option value="10">10</option>
                        <option value="30">30</option>
                        <option value="90">90</option>
                    </select>
                </div>
                </div>  
                <div class="col-lg-3 col-md-12">
                <label for="oneriRichiesti" class="form-label">Oneri richiesti per l'allaccio</label>
                <div class="input-group">
                    <span class="input-group-text" id="inputGroup-sizing-default">&euro;</span>
                    <input type="number" class="form-control width-20" id="oneriRichiesti" v-model="item.oneriRichiesti"  @change="updateItem" >
                </div>            
                </div>                    
                <div class="col-lg-3 col-md-12">
                <label for="oneriInclusi" class="form-label">Oneri inclusi da contratto</label>
                <div class="input-group">
                    <span class="input-group-text" id="inputGroup-sizing-default">&euro;</span>
                    <input type="number" class="form-control width-20" id="oneriInclusi" v-model="item.oneriInclusi"  @change="updateItem" >
                </div>            
                </div>
                <div class="col-lg-3 col-md-12">
                    <label for="oneriDaRiscuoter" class="form-label">Oneri da riscuotere</label>
                    <div class="input-group">
                        <span class="input-group-text" id="inputGroup-sizing-default">&euro;</span>
                        <input type="number" class="form-control width-20" id="oneriDaRiscuoter" v-model="item.oneriDaRiscuoter"  @change="updateItem" >
                    </div>            
                </div>
            </div>  
            <div class="row  back-grey" >
                <div class="col-lg-6 col-md-12">
                <label for="note" class="form-label">Note (eventuali)</label>
                <div class="input-group" >
                    <textarea class="form-control" id="responseMSGInput" rows="0.5" placeholder="Inserisci eventuali note" v-model="item.note" @blur="updateItem"></textarea>        
                </div>
                </div>  
                <div class="col-lg-6 col-md-12">
           
                </div>
            </div> 
        </template>
        <!-- FEEDBACK UT-->
        <div class="row  mt-50 back-white ">
                <div class="col-lg-12 col-md-12 text-center mt-10 h_ut">
                    <h3>Feedback Ufficio tecnico</h3>       
                </div>      
            </div>    
            <div class="row  back-grey" >
                <div class="col-lg-3 col-md-12">
                <label for="dataAccettazione" class="form-label">Data accettazione</label>
                <div class="input-group" >
                    <input type="date" class="form-control width-20" id="dataAccettazione" v-model="item.dataAccettazione"  @change="convertDate('dataAccettazione',item.dataAccettazione)" >
                </div>
                </div>  
                <div class="col-lg-3 col-md-12">
                <label for="scadenzaRichAutorizzazioni" class="form-label">Scandenza richiesta autorizzazioni:</label>
                <div class="input-group">
                    <input type="date" class="form-control width-20" id="scadenzaRichAutorizzazioni" v-model="item.scadenzaRichAutorizzazioni"  @change="convertDate('scadenzaRichAutorizzazioni',item.scadenzaRichAutorizzazioni)" >
                </div>            
                </div>                    
                <div class="col-lg-3 col-md-12">
                <label for="codIdePratica" class="form-label">Codice Identificativo pratica</label>
                <div class="input-group">
                    <input type="input" class="form-control width-20" id="codIdePratica" v-model="item.codIdePratica"  @change="updateItem" >
                    </div>            
                </div>
            </div>   
            <div class="row  back-grey" >
                <div class="col-lg-3 col-md-12">
                <label for="dataRispostaEnel" class="form-label">Data risposta Enel (Fine lavori)</label>
                <div class="input-group" >
                    <input type="date" class="form-control width-20" id="dataRispostaEnel" v-model="item.dataRispostaEnel"  @change="convertDate('dataRispostaEnel',item.dataRispostaEnel)" >
                </div>
                </div>  
                <div class="col-lg-2 col-md-12">
                <label for="dataFineLavori" class="form-label">Fine lavori:</label>
                <div class="input-group">
                    <input type="date" class="form-control width-20" id="dataFineLavori" v-model="item.dataFineLavori"  @change="convertDate('dataFineLavori',item.dataFineLavori)" >
                </div>            
                </div>                    
                <div class="col-lg-2 col-md-12">
                <label for="dataIpotesiAllaccio" class="form-label">Data ipotizzata per allaccio</label>
                <div class="input-group">
                    <input type="date" class="form-control width-20" id="dataIpotesiAllaccio" v-model="item.dataIpotesiAllaccio"  @change="convertDate('dataIpotesiAllaccio',item.dataIpotesiAllaccio)" >
                    </div>            
                </div>
                <div class="col-lg-2 col-md-12">
                <label for="dataAllaccio" class="form-label">Data allaccio</label>
                <div class="input-group">
                    <input type="date" class="form-control width-20" id="dataAllaccio" v-model="item.dataAllaccio"  @change="convertDate('dataAllaccio',item.dataAllaccio)" >
                    </div>            
                </div>
            </div>  

        <!-- / FEEDBACK UT-->
        <div class="row  back-grey mt-50" >
            <div class="col-lg-12 col-md-12 mt-30 text-center">
                <b-button pill variant="success" class="btn-label waves-effect waves-light" ><i class="ri-add-line label-icon align-middle rounded-pill fs-16 me-2"></i> Autorizza installazione</b-button>              
            </div>
        </div>

        </template>
        <template v-else>
          <div class="row  mt-50 back-white" >
            <div class="col-lg-12 col-md-12 text-center">
              <b-button pill variant="success" class="btn-label waves-effect waves-light" @click="postItem" ><i class="ri-add-line label-icon align-middle fs-16 me-2"></i> Avvia pratica Enel</b-button>              
            </div>     
          </div>  
        </template>

      </template>
      <template v-else>
          <div class="row  mt-50 back-white" >
            <div class="col-lg-12 col-md-12 text-center">
              <b-button pill variant="secondary" class="btn-label waves-effect waves-light" @click="postItem" ><i class="ri-add-line label-icon align-middle fs-16 me-2"></i> Avvia pratica Enel</b-button>              
             
            </div>     
          </div>  
      </template>

    </form>




</template>
