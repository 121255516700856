<script>
import moment from 'moment'
import serverConfig from '@/helpers/config';
import { useCookies } from "vue3-cookies";
import Swal from "sweetalert2";
import axios from 'axios';

export default {
  name: "gseTabPV",
  emits: ["getParent"],  
  props: [
    'ID',
    'data',
    'open',
  ],
  setup() {
   
    moment.locale('it')

    const { cookies } = useCookies();    
    let tk = 'Bearer '+cookies.get('tk')

    return { tk};
  },
  data() {
    return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        results_n:0,
        showGse:false,
        item:{
            projectID:'',
            gseID:'',
            status:'',
            regimeContrattuale:'',
            dataEntrataInEsercizio:'',
            dataEntrataInEsercizioTmp:0,
            daLavorareEntro:'',
            daLavorareEntroTmp:0,
            dataRegPratica:'',
            dataRegPraticaTmp:0,
            dataEsito:'',
            dataEsitoTmp:0,
            tipoEsito:'',
            dataStipula:'',
            dataStipulaTmp:'',
            tariffaAuto:0,
            tariffaOmni:0,
            note:'',
            noteExtra:'',
            idImpianto:'',
            UserId:'',
            password:'',
            createdBy: this.$store.state.auth.currentUser, 
        },

    };
  },
    validations: {
    },

  mounted() {
    if (this.data.gse){
        this.item = this.data.gse
    }
     
  },

  methods: {
    convertDate(type,val){
        switch(type){
            case "dataEntrataInEsercizio":
                this.item.dataEntrataInEsercizioTmp =  moment(val).unix()
                break;
            case "daLavorareEntro":
                this.item.daLavorareEntroTmp =  moment(val).unix()
                break;
            case "dataEsito":
                this.item.dataEsitoTmp =  moment(val).unix()
                break;
            case "dataValiditaPrevgse":
                this.item.dataValiditaPrevgseTmp =  moment(val).unix()
                break;      
            case "dataScadenzaPrevgse":
                this.item.dataScadenzaPrevgseTmp =  moment(val).unix()
                break;  
            case "dataAccettazione":
                this.item.dataAccettazioneTmp =  moment(val).unix()
                break;  
            case "scadenzaRichAutorizzazioni":
                this.item.scadenzaRichAutorizzazioniTmp =  moment(val).unix()
                break;  
            case "dataFineLavori":
                this.item.dataFineLavoriTmp =  moment(val).unix()
                break;                
            case "dataIpotesiAllaccio":
                this.item.dataIpotesiAllaccioTmp =  moment(val).unix()
                break; 
            case "dataAllaccio":
                this.item.dataAllaccioTmp =  moment(val).unix()
                break;                 
        }
        
       this.updateItem()
    },
    addElement(){
      
      let countL = this.roofPitch.rpList.length
      
      this.roofPitch.rpList.push({
          row:countL+1,
          number:0,
          panelQty:0,
          latitute:0,
          longitude:0,
          power:0.00,
          roofShapes: '', // Forma del tetto
          roofTilt:0.00,  // inclinazione
          orientation:0.00,    // orientamento
          azimuth:0.00,
          deviationFromMax:0.00,
          note: '',
      })
      this.roofPitch.totalRP = this.roofPitch.rpList.length
      this.updateItem()
      this.$emit('getParent')
    },
    removeElement(n){
      let l = this.roofPitch.rpList.filter(function(el){
        return el.row !== n
      })
      this.roofPitch.rpList = l
      this.roofPitch.totalRP = l.length
      //this.regenList()
      this.updateItem()
    },
    getItem(){
      //  if( 'gse' in this.data){
        //  if( 'gseID' in this.data.gse){
           axios.get(`${this.UrlServer}allacci/gse/${this.data.gse.gseID}`, {headers: { authorization:this.tk}} )
             
             .then((response)=>{
                this.results_n = response.data.length
                this.item = response.data
              })
              .catch((error)=>{
                if(error.response.status==404)
                {
                  Swal.fire( this.$t("t-msg-error-404") , this.$t("t-msg-error-content-404"), "info");
                } else if(error.response.status==401){
                  Swal.fire( this.$t("t-msg-error-401") , this.$t("t-msg-error-content-401"), "error");
                } else if ( error.response.status==401)
                {
                  console.log(error)
                } else if ( error.response.status>=500){
                  Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
                }

            });
         // }
       // }
      }, 
      
    postItem(){
       // this.submitted = true;
        //this.v$.$touch();
        this.item.projectID=this.ID
        
        axios.post(`${this.UrlServer}allacci/registra/gse`, this.item, {headers: { authorization:this.tk}} )
          .then((response)=>{
            if (response.data){
              this.item.gseID = response.data.insertId._id
              this.results_n = 1
              this.updateItem()
            }
            
          })
          .catch((error)=>{
            if(error.response.status==404)
            {
              Swal.fire( this.$t("t-msg-error-404") , this.$t("t-msg-error-content-404"), "info");
            } else if(error.response.status==401){
              Swal.fire( this.$t("t-msg-error-401") , this.$t("t-msg-error-content-401"), "error");
            } else if ( error.response.status==401)
            {
              console.log(error)
            } else if ( error.response.status>=500){
              Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
            }
             
          });
      },
    updateItem(){
       // this.submitted = true;
        //this.v$.$touch();
        this.item.projectID=this.ID
        
        axios.put(`${this.UrlServer}allacci/gse/${ this.item.gseID}`, this.item, {headers: { authorization:this.tk}} )
          .then((response)=>{
            if (response.data){
              localStorage.setItem('prjTab','tab-gse')
              
              this.results_n = 1
              this.$emit('getParent')
            }
            
          })
          .catch((error)=>{
            if(error.response.status==404)
            {
              Swal.fire( this.$t("t-msg-error-404") , this.$t("t-msg-error-content-404"), "info");
            } else if(error.response.status==401){
              Swal.fire( this.$t("t-msg-error-401") , this.$t("t-msg-error-content-401"), "error");
            } else if ( error.response.status==401)
            {
              console.log(error)
            } else if ( error.response.status>=500){
              Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
            }
             
          });
      },
  },
  components: {
    
  },

};
</script>

<template>
    <form action="">
      <template v-if="data.gse">
        <template v-if="data.gse.gseID">
        <div class="row  mt-50 back-white" >
          <div class="col-lg-12 col-md-12 text-center h_gse">
                <h3>Pratica GSE
                </h3>       
            </div>      
        </div>            
        <div class="row  back-grey" >
            <div class="col-lg-3 col-md-12 ">
              <label for="dataEntrataInEsercizio" class="form-label">Data entrata in esercizio</label>
              <div class="input-group" >
                <input type="date" class="form-control width-20" id="dataEntrataInEsercizio" v-model="item.dataEntrataInEsercizio "  @change="convertDate('dataEntrataInEsercizio',item.dataRichiesta)" >
              </div>
            </div>          
            <div class="col-lg-3 col-md-12">
              <label for="daLavorareEntro" class="form-label">Lavorazione entro il</label>
              <div class="input-group">
                <input type="date" class="form-control width-20" id="daLavorareEntro" v-model="item.daLavorareEntro"  @change="convertDate('daLavorareEntro',item.daLavorareEntro)" >
              </div>            
            </div>
            <div class="col-lg-3 col-md-12">
              <label for="regimeContrattuale" class="form-label">Conferma lavorazione regime contrattuale</label>
                <select class="form-control" id="regimeContrattuale" v-model="item.regimeContrattuale"  @change="updateItem">
                     <option value="ritiro_dedicato">ritiro dedicato</option>
                    <option value="tariffa_incentivante">tariffa incentivante</option>
                    <option value="scambio_sul_posto">scambio sul posto</option>
                   
                </select>
            </div>
                        
        </div>
        <div class="row  mt-50 back-white" >
          <div class="col-lg-12 col-md-12 text-center h_gse">
                <h4>Feedback GSE</h4>       
            </div>      
        </div>    
        <div class="row  back-grey" >
            <div class="col-lg-3 col-md-12 ">
              <label for="dataEsito" class="form-label">Data esito GSE</label>
              <div class="input-group" >
                <input type="date" class="form-control width-20" id="dataEsito" v-model="item.dataEsito"  @change="convertDate('dataEsito',item.dataEsito)" >
              </div>
            </div>                   
            <div class="col-lg-3 col-md-12">
              <label for="tipoEsito" class="form-label">Tipo esito</label>
              <div class="input-group">
                <select class="form-control" id="tipoEsito" v-model="item.tipoEsito"  @change="updateItem">
                    <option value="registra_convenzione">registra convenzione</option>
                    <option value="richiesta_integrazione">richiesta integrazione</option>
                    <option value="richiesta_respinta">richiesta respinta del GSE</option>
                </select>
                </div>            
            </div>
        </div>
         <div class="row  back-grey" >
            <div class="col-lg-3 col-md-12 mt-20 pr-10">
                <div class="form-check form-switch form-switch-custom form-switch-info pr-20">
                    <label for="showGse" class="form-label">Visualizza credenziali GSE</label>
                    <input class="form-check-input pr-20" type="checkbox" role="switch" id="showGse" v-model="showGse">
                </div>
            </div>
         </div>
        <div class="row  back-grey" v-if="showGse">
            <div class="col-lg-3 col-md-12 ">
              <label for="dataEsito" class="form-label">ID Impianto</label>
              <div class="input-group" >
                <input type="date" class="form-control width-20" id="dataEsito" v-model="item.dataEsito"  @change="convertDate('dataEsito',item.dataEsito)" >
              </div>
            </div>
            <div class="col-lg-3 col-md-12">
              <label for="tipoEsito" class="form-label">User ID</label>
              <div class="input-group">
                <input type="input" class="form-control width-20" id="dataEsito" v-model="item.dataEsito" >
              </div>
            </div>             
            <div class="col-lg-3 col-md-12">
              <label for="tipoEsito" class="form-label">Password</label>
              <div class="input-group">
                    <input type="input" class="form-control width-20" id="dataEsito" v-model="item.dataEsito" >
                </div>            
            </div>
        </div>
       
        </template>
        <template v-else>
          <div class="row  mt-50 back-white" >
            <div class="col-lg-12 col-md-12 text-center">
              <b-button pill variant="success" class="btn-label waves-effect waves-light" @click="postItem" ><i class="ri-add-line label-icon align-middle fs-16 me-2"></i> Avvia pratica gse</b-button>              
            </div>     
          </div>  
        </template>

      </template>
      <template v-else>
          <div class="row  mt-50 back-white" >
            <div class="col-lg-12 col-md-12 text-center">
              <b-button pill variant="success" class="btn-label waves-effect waves-light" @click="postItem" ><i class="ri-add-line label-icon align-middle fs-16 me-2"></i> Avvia pratica GSE</b-button>              
            </div>     
          </div>  
      </template>

    </form>




</template>
