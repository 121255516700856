<script>
import { mapActions } from "vuex";

import moment from 'moment'
import serverConfig from '@/helpers/config';
import Swal from "sweetalert2";
import axios from 'axios';

export default {
  name: "inizioLavoriTabPV",
  emits: ["getParent"],  
  props: [
    'ID',
    'data',
    'open',
  ],
  setup() {
    moment.locale('it')   
    let tk = 'Bearer '+localStorage.getItem('tk')

    return { tk};
  },
  data() {
    return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        results_n:0,
        showGse:false,
        item:{
          authorizationtoStartWork: {
            responsiblePerson:'',
            responsiblePersonPhone:'',
            email:'',
            type:'', // 'Comunicazione di inizio lavori','Dia', 'Duap'
            protocol:'',
            protocolDate:'',
            protocolDateTmp:0,
            terminiSilenzioAssenso:{ type: Number, default:30}, // 30 giorni di default
            scadenzaSilenzioAssenso:'', 
            scadenzaSilenzioAssensoTmp:0,
            feedbackDateEnte:'',
            feedbackDateEnteTmp:0,
            feedbackNote:'',
            status:'', // 'favorevole', 'non favorevole', 'Non favorevole, per richiesta integrazione',
            response:{type:Boolean}, // procedi alla cantierabilità TRUE/FALSE
            createdBy: this.$store.state.auth.currentUser, 
          }
        },
        atswSupplements:{        
          total:0,
          suppList:[]
        },
    };
  },
    validations: {
    },

  mounted() {
    if (this.data.authorizationtoStartWork){
        this.item.authorizationtoStartWork = this.data.authorizationtoStartWork
    }
    if (this.data.authorizationtoStartWork){
        
        this.item.authorizationtoStartWork = this.data.authorizationtoStartWork
    }
    if (!this.data.atswSupplements) {
      this.atswSupplements.total = 0
    } else {
      this.atswSupplements = this.data.atswSupplements
    }

  },

  methods: {
    ...mapActions('layout', ['changeLayoutType', ]),
    axiosInterceptor(){
      axios.interceptors.response.use(
            (response) => {
                
                return response;
            },
            (error) => {
                if (error.response && error.response.status === 401) {
                  Swal.fire({
                    title:"Accesso non autorizzato",
                    text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:3500,
                  })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }
                    return Promise.reject('Unauthorized access');
                } else if (error.response && error.response.status  === 404) {
                    Swal.fire({
                      title:"Nessun risultato trovato",
                      text:"Mi spiace, nessun risultato trovato",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('The server cannot find the requested resource');
                }  else if (error.response && error.response.status  === 412) {
                  Swal.fire({
                    title:"Credenziali mancanti",
                    text:"Inserisci delle credenziali valide, per favore.",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              } else if (error.response && error.response.status  === 423) {
                    Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }                
                    return Promise.reject('The server cannot find the requested resource');
                }
                else if (error.response && error.response.status  === 500) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Internal Server Error');
                }
                else if (error.response && error.response.status  === 502) {
                    Swal.fire({
                      title:"Errore Server",
                      text:"Mi spiace, ma riscontriamo degli errori sul server.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('Bad Gateway');
                }
                else if (error.response && error.response.status  === 503) {
                  Swal.fire({
                    title:"Servizio non disponibile",
                    text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Service Unavailable');
                } else {
                  console.log(error.response.status )
                  Swal.fire({
                    title:"Errore imprevisto",
                    text:"Ops! si è verificato un problema...riprova, per favore.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  
                }

            }
          );
    },  
    convertDate(type,val){
        switch(type){
            case "protocolDate":
                this.item.protocolDateTmp =  moment(val).unix()
                break;
            case "scadenzaSilenzioAssenso":
                this.item.scadenzaSilenzioAssensoTmp =  moment(val).unix()
                break;
            case "feedbackDateEnte":
                this.item.feedbackDateEnteTmp =  moment(val).unix()
                break;             
        }
        
       this.updateItem()
    },
    convertDateRow(r, type,val){
      //atswSupplements.suppList

      for (let item of this.atswSupplements.suppList) {
        if(item.row == r ){
          switch(type){
            case "dataSpedizione":
                item.dataSpedizioneTmp =  moment(val).unix()
                break;
            case "dataEsito":
                item.dataEsitoTmp =  moment(val).unix()
                break;
          }
        }
      }
        
       this.updateItem()
    },
    addElement(){
      
      let countL = this.atswSupplements.suppList.length
      
      this.atswSupplements.suppList.push({
        row:countL+1,
        status:'',
        type:'', // 'detrazione fiscale, 'bando_regionale', 'certificazione_energetica
        nome:'', 
        dataSpedizione:'',
        dataSpedizioneTmp:0,
        dataConsegna:'',
        dataConsegnaTmp:0,  
        tempistica:0,  
        dataPrevEsito:'',
        dataPrevEsitoTmp:0,
        dataEsito:'',
        dataEsitoTmp:0,
        tipoEsito:'', // 'ok, registra convenzione', etc.
        note:'',
        noteExtra:'',
      })
      this.atswSupplements.total = this.atswSupplements.suppList.length
      this.updateItem()
      this.$emit('getParent')
    },
    removeElement(n){

      Swal.fire(
          {
            title: this.$t("t-delete-confirm-setting-title"),
            text: this.$t("t-delete-confirm-setting-text"),
            icon: this.$t("t-delete-confirm-setting-icon"),
            showCancelButton: true,
            cancelButtonText: this.$t("t-delete-confirm-cancel-button-text"),
            cancelButtonColor: this.$t("t-delete-confirm-setting-cancelButtonColor"),
            confirmButtonColor: this.$t("t-delete-confirm-setting-confirmButtonColor"),
            confirmButtonText: this.$t("t-delete-confirm-setting-confirmButtonText")
          }
        ).then((result) => {
          if (result.value) {
                let l = this.atswSupplements.suppList.filter(function(el){
            return el.row !== n
          })
          this.atswSupplements.suppList = l
          this.atswSupplements.total = l.length
          //this.regenList()
          this.updateItem()
          }
        });

    },
    updateItem(){
      let data = {
        authorizationtoStartWork:this.item.authorizationtoStartWork,
        atswSupplements:this.atswSupplements
      }
      this.axiosInterceptor()
      axios.put(`${this.UrlServer}projects/${this.ID}`, data, {headers: { authorization:this.tk}} )
        .then(()=>{
          localStorage.setItem('prjTab','tab-iniziolavori')
          this.$emit('getParent')
        })
      },
  },
  components: {
    
  },

};
</script>

<template>
    <form action="">
      <template v-if="data.gse">
        <template v-if="data.gse.gseID">
        <div class="row  mt-50 back-white" >
          <div class="col-lg-12 col-md-12 text-center h_ia">
                <h3>
                  Autorizzazione Inizio Lavori
                </h3>       
            </div>      
        </div>
        <div class="row  mt-50 back-white" >
          <div class="col-lg-12 col-md-12 text-center h_ia">
                <h4>Contatti di riferimento</h4>       
            </div>      
        </div>   
        <div class="row  back-grey" >
            <div class="col-lg-3 col-md-12 ">
              <label for="responsiblePerson" class="form-label">Responsile del procedimento</label>
              <div class="input-group" >
                <input type="input" class="form-control width-20" id="responsiblePerson" v-model="item.authorizationtoStartWork.responsiblePerson"  @change="updateItem" >
              </div>
            </div>                   
            <div class="col-lg-3 col-md-12 ">
              <label for="responsiblePersonPhone" class="form-label">Contatto telefonico</label>
              <div class="input-group" >
                <input type="input" class="form-control width-20" id="responsiblePersonPhone" v-model="item.authorizationtoStartWork.responsiblePersonPhone"  @change="updateItem" >
              </div>
            </div>
            <div class="col-lg-3 col-md-12 ">
              <label for="email" class="form-label">Email Contatto</label>
              <div class="input-group" >
                <input type="email" class="form-control width-20" id="email" v-model="item.authorizationtoStartWork.email"  @change="updateItem" >
              </div>
            </div>
        </div>                    
        <div class="row  mt-50 back-white" >
          <div class="col-lg-12 col-md-12 text-center h_ia">
                <h4>Titoli autorizzativi</h4>       
            </div>      
        </div>    
        <div class="row  back-grey" >
                  
            <div class="col-lg-2 col-md-12 text-center">
              <label for="typeAI" class="form-label">Tipologia di titolo</label>
              <div class="input-group">
                <select class="form-control" id="typeAI" v-model="item.authorizationtoStartWork.type"  @change="updateItem">
                    <option value="registra_convenzione">registra convenzione</option>
                    <option value="richiesta_integrazione">richiesta integrazione</option>
                    <option value="richiesta_respinta">richiesta respinta del GSE</option>
                </select>
                </div>            
            </div>
            <div class="col-lg-2 col-md-12 text-center">
              <label for="ente" class="form-label">Ente erogante</label>
              <div class="input-group">
                <input type="input" class="form-control width-20" id="ente" v-model="item.authorizationtoStartWork.ente"  @change="updateItem" >
              </div>            
            </div>
            <div class="col-lg-2 col-md-12 text-center">
              <label for="protocol" class="form-label">Protocollo Generale</label>
              <div class="input-group">
                <input type="input" class="form-control width-20" id="protocol" v-model="item.authorizationtoStartWork.protocol"  @change="updateItem" >
              </div>            
            </div>
            <div class="col-lg-2 col-md-12 text-center">
              <label for="protocolDate" class="form-label">Data</label>
              <div class="input-group">
                <input type="date" class="form-control width-20" id="protocolDate" v-model="item.authorizationtoStartWork.protocolDate"  @change="convertDate('protocolDate',item.authorizationtoStartWork.protocolDate)" >
              </div>            
            </div>
            <div class="col-lg-2 col-md-12 text-center">
              <label for="terminiSilenzioAssenso" class="form-label">Termini Silenzio Assenso</label>
              <div class="input-group">
                <select class="form-control" id="terminiSilenzioAssenso" v-model="item.authorizationtoStartWork.terminiSilenzioAssenso"  @change="updateItem">
                    <option value="30">30 gg</option>
                    <option value="60">60 gg</option>
                    <option value="90">90 gg</option>
                </select>
                </div>            
            </div>
            <div class="col-lg-2 col-md-12 text-center">
              <label for="scadenzaSilenzioAssenso" class="form-label">Data Scadenza silenzio assenso</label>
              <div class="input-group">
                <input type="date" class="form-control width-20" id="scadenzaSilenzioAssenso" v-model="item.authorizationtoStartWork.scadenzaSilenzioAssenso"  @change="convertDate('scadenzaSilenzioAssenso',item.authorizationtoStartWork.scadenzaSilenzioAssenso)" >
              </div>            
            </div>            
        </div>

        <div class="row  mt-50 back-white" >
          <div class="col-lg-12 col-md-12 text-center h_ia_feedb">
                <h4>
                  Esito richiesta
                </h4>       
            </div>      
        </div>
        <div class="row  back-grey" >
            <div class="col-lg-3 col-md-12 ">
              <label for="feedbackDateEnte" class="form-label">Data risposta da parte dell'Ente</label>
              <div class="input-group" >
                <input type="date" class="form-control width-20" id="feedbackDateEnte" v-model="item.authorizationtoStartWork.feedbackDateEnte"  @change="convertDate('feedbackDateEnte',item.authorizationtoStartWork.feedbackDateEnte)" >
              </div>
            </div>
            <div class="col-lg-3 col-md-12 ">
              <label for="tipoEsito" class="form-label">Esito</label>
              <div class="input-group" >
                    <select class="form-control" id="tipoEsito" v-model="item.authorizationtoStartWork.tipoEsito"  @change="updateItem">
                    <option value="favorevole">Favorevole</option>
                    <option value="favorevole_e_cantierabile">favorevole e cantierabile</option>
                    <option value="richiesta_integrazione">richiesta integrazione</option>
                    <option value="non_favorevole">Non favorevole</option>
                </select>              </div>
            </div>                  
            <div class="col-lg-6 col-md-12 ">
              <label for="note" class="form-label">Note</label>
              <div class="input-group" >
                    <textarea class="form-control" id="note" rows="0.1" placeholder="Inserisci eventuali note" v-model="item.authorizationtoStartWork.note" @blur="updateItem"></textarea>        
              </div>
            </div>

        </div>    
        <div class="row  mt-50 back-white" v-if="atswSupplements.total>0 || item.authorizationtoStartWork.tipoEsito=='richiesta_integrazione'" >
          <div class="col-lg-12 col-md-12 text-center mt-2">
                <h3>Integrazioni</h3>       
            </div>      
        </div>
      <template v-if="atswSupplements">
            <div class="row  back-white"  >
                <div class="col-lg-2 col-md-12">
                    <div class="input-group ">
                      <label class="form-label">Nome</label>
                    </div>
                </div>
                <div class="col-lg-2 col-md-12">
                    <label  class="form-label">Data Spedizione</label>
                </div> 
                <div class="col-lg-2 col-md-12">
                    <label class="form-label">Data ricezione esito</label>
                </div>         
                <div class="col-lg-5 col-md-12">
                    <label class="form-label">Note</label>        
                </div>
                <div class="col-lg-1 col-md-12">
                   Azioni
                </div>
            </div>        
        <template v-if="atswSupplements.total > 0">
            <div class="row  back-grey"  v-for="i in atswSupplements.suppList" :key="i.row">
                <div class="col-lg-2 col-md-12">
                    <div class="input-group mt-5">
                      <input type="text" class="form-control width-10" :id="`nome_${i.row}`" v-model="i.nome"  @blur="updateItem" >
                    </div>
                </div>
                <div class="col-lg-2 col-md-12">
                    <div class="input-group  mt-5">
                      <input type="date" class="form-control width-10" :id="`dataSpedizione_${i.row}`"  v-model="i.dataSpedizione" @change="convertDateRow(i.row,'dataSpedizione',i.dataSpedizione)">
                    </div>
                </div> 
                <div class="col-lg-2 col-md-12">
                    
                    <div class="input-group  mt-5">
                      <input type="date" class="form-control width-10" :id="`dataEsito_${i.row}`"  v-model="i.dataEsito"  @change="convertDateRow(i.row,'dataEsito',i.dataEsito)" >
                    </div>
                </div>         
                <div class="col-lg-5 col-md-12">
                    <div class="input-group  mt-5">
                        
                        <textarea class="form-control" :id="'note_'+i.row" rows="0.1" placeholder="Inserisci eventuali note" v-model="i.note" @blur="updateItem"></textarea>        
                    </div>            
                </div>
                <div class="col-lg-1 col-md-12">
                    <div class="input-group  mt-5">
                        <b-button variant="danger" class="btn-icon waves-effect waves-light" title="Rimuovi integrazioni" @click="removeElement(i.row)"><i class="ri-delete-bin-5-line"></i></b-button>
                    </div>
                </div>
            </div>
        </template>
    </template>

        <div class="row  back-grey mt-50" >
            <div class="col-lg-12 col-md-12 mt-30 text-center">
                <b-button pill variant="primary" class="btn-label waves-effect waves-light" @click="addElement"><i class="ri-add-line label-icon align-middle rounded-pill fs-16 me-2"></i> Aggiungi integrazione</b-button>              
            </div>
        </div>

   
        </template>
       
      </template>


    </form>




</template>
